import React, { useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbondEventKeys from 'src/constants/locale/key/AceHighwayAbiInbondEvent'
import {
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core'
import { components, DateTimeFormatter, useServices, useTranslation, constants } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import makeValidationSchema from './MakeValidationSchema'
import NaUSPortAutocompleteField from 'src/components/na/autocomplete/codemaintenance/NaUSPortAutocompleteField'
import { NaAceStateCodeAutoCompleteField } from 'src/components/na'
import {FirmCodesAutocompleteField} from 'src/components/na'
import CngSection from '../../../components/cngcomponents/CngSection'
import moment from 'moment-timezone'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import { useLocation } from 'react-router-dom'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton, CngIconButton },
  form: {
    field: {
      CngCheckboxField,
      CngSelectField,
      CngTextField,
      CngDateField,
      CngTimeField,
      CngDateTimeField,
      CngCodeMasterAutocompleteField
    }
  },
  table: { useFetchCodeMaintenanceLookup },
  CngGridItem,
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  submittedBy: "",
  submittedDate: "",
  version: "",
  partyId: "",
  chargeAs: "",
  filerCode: "SY2",
  portCode: "0901",
  msgFunction: "1",
  inbond7512No: "",
  masterBolIssuerCode: "",
  masterBol: "",
  houseBol: "",
  firmLocation: "",
  containerNumber: "",
  inbondDate: "",
  arrivalDate: "",
  arrivalTime: "",
  inbondDestination: "",
  inbondCarrierCode: "",
  bondedCarrierId: "",
  city: "",
  stateCode: "",
  exportMot: "",
  exportConveyance: "",
  responseDate: "",
  isEvent: true
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { securedSendRequest, deleteRecord } = useServices();
  const { setValue, getValues, watch, reset, trigger } = useFormContext();
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT)
  const translatedTextsObject = makeTranslatedTextsObject()
  const location = useLocation();
  const { id } = useParams()
  const filerCode = watch('filerCode')
  const portCode = watch('portCode')
  const msgFunction = watch('msgFunction')
  const exportMot = watch('exportMot')
  const city = watch('city')

  const [defaultFilerCode, setDefaultFilerCode] = useState("SY2")
  const [defaultPortCode, setDefaultPortCode] = useState("0901")
  const [filerDropDown, setFilerDropDown] = useState()
  const [filerPortCodeList, setFilerPortCodeList] = useState()
  const [user, setUser] = useState([]);
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => { 
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest,fileForUserDetails); 
  }, []);
  
  useEffect(() => {
    Promise.all([
      // Default Filer Code & Port Code
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ABI_INBOND_DEFAULT_VALUE' }],
        undefined, 'code'
      )
    ]).then(([defaultABIValue]) => {
      if (null !== defaultABIValue) {
        if (undefined !== defaultABIValue.ABI_INBOND_DEFAULT_FILER_CODE) {
          setDefaultFilerCode(defaultABIValue.ABI_INBOND_DEFAULT_FILER_CODE)
        }
        if (undefined !== defaultABIValue.ABI_INBOND_DEFAULT_PORT_CODE) {
          setDefaultPortCode(defaultABIValue.ABI_INBOND_DEFAULT_PORT_CODE)
        }
      }
    }
    )
  }, [])

  useEffect(() => {
    if (user) {
      let filerCodeDropDown = [];
      let filerPortList = [];

      if (user.filerPort && user.filerPort.length === 0) {
        let filerDropDownObj = {
          text: defaultFilerCode,
          value: defaultFilerCode
        }
        let filerPortObj = {
          text: defaultFilerCode,
          value: defaultPortCode
        }
        filerCodeDropDown.push(filerDropDownObj)
        filerPortList.push(filerPortObj)

        if (filerCode === defaultFilerCode && portCode !== defaultPortCode) {
          setValue("portCode", defaultPortCode)
        }
      }

      if (user.filerPort && user.filerPort.length > 0) {
        filerCodeDropDown = [];
        filerPortList = [];
        user.filerPort.forEach((value) => {
          let filerDropDownObj = {
            text: value.filerCode,
            value: value.filerCode
          }
          let filerPortObj = {
            text: value.filerCode,
            value: value.portCode
          }

          filerCodeDropDown.push(filerDropDownObj)
          filerPortList.push(filerPortObj)

          if (filerCode === value.filerCode && portCode !== value.portCode) {
            setValue("portCode", value.portCode)
          }
        })
      }

      if (filerCodeDropDown.length > 0 && !filerCodeDropDown.includes(filerCode)) {
        setValue("filerCode", filerCodeDropDown[0].value)
      }

      setFilerDropDown(filerCodeDropDown)
      setFilerPortCodeList(filerPortList)
    }
  }, [user, defaultFilerCode, defaultPortCode]);

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let abiInbondEvent = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.TITLE
    )
    let id = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ID
    )
    let createdBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.UPDATED_DATE
    )
    let submittedBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.SUBMITTED_DATE
    )
    let version = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.VERSION
    )
    let partyId = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.PARTY_ID
    )
    let filerCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.FILER_CODE
    )
    let portCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.PORT_CODE
    )
    let msgFunction = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ACTION_CODE
    )
    let inbond7512No = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.INBOND_NUMBER
    )
    let masterBolIssuerCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.MASTER_BOL_ISSUER_CODE
    )
    let houseBol = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.HOUSE_BOL
    )
    let firmLocation = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.FIRM_LOCATION
    )
    let containerNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.CONTAINER_NUMBER
    )
    let arrivalDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ARRIVAL_DATE
    )
    let arrivalTime = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ARRIVAL_TIME
    )
    let inbondDestination = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ARRIVAL_PORT
    )
    let inbondCarrierCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.INBOND_CARRIER_CODE
    )
    let bondedCarrierId = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.BONDED_CARRIER_ID
    )
    let city = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.CITY
    )
    let stateCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.STATE_CODE
    )
    let exportMot = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.EXPORT_MOT
    )
    let exportConveyance = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.EXPORT_CONVEYANCE
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.STATUS
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.RESPONSE_DATE
    )

    return {
      abiInbondEvent,
      id,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      submittedBy,
      submittedDate,
      version,
      partyId,
      filerCode,
      portCode,
      msgFunction,
      inbond7512No,
      masterBolIssuerCode,
      houseBol,
      firmLocation,
      containerNumber,
      arrivalDate,
      arrivalTime,
      inbondDestination,
      inbondCarrierCode,
      bondedCarrierId,
      city,
      stateCode,
      exportMot,
      exportConveyance,
      status,
      responseDate
    }
  }

  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection
          title='Inbond Event Header'
          subheader={
            <Typography color='textSecondary' variant='caption'>
              <Typography variant='inherit' color='error'>
                *
              </Typography>
              Mandatory fields
            </Typography>
          }
        >
          <Grid container spacing={1}>
                {/* <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.msgFunction}>
                  <CngCodeMasterAutocompleteField
                    required
                    name='msgFunction'
                    label={translatedTextsObject.msgFunction}
                    disabled={disabled}
                    codeType='ACE_HW_ABI_ACTION'
                  />
                </CngGridItem> */}
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.filerCode}>
                <CngSelectField
                  required
                  name="filerCode"
                  label={translatedTextsObject.filerCode}
                  disabled={disabled}
                  items={filerDropDown}
                  onChange={(e) => {
                    setValue('portCode', filerPortCodeList.find(obj => obj.text === e.target.value).value)
                  }}
                  size='small'
                />
              </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.inbond7512No}>
                  <CngTextField
                    required={msgFunction !== '2' && msgFunction !== '6'}
                    name='inbond7512No'
                    label={translatedTextsObject.inbond7512No}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 12 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('inbond7512No', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                    size='small'
                  />
                </CngGridItem>
                
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.masterBolIssuerCode}>
                <CngTextField
                    required={msgFunction === '2' || msgFunction === '3' || msgFunction === '6' || msgFunction === '7'}
                    name='masterBolIssuerCode'
                    label={translatedTextsObject.masterBolIssuerCode}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, ...(exportMot === "40" ? {maxLength: 11} : {maxLength: 16})}}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('masterBolIssuerCode', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                    size='small'
                  />
                </CngGridItem>
                {exportMot === "40" && (
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.houseBol}>
                    <CngTextField
                      name='houseBol'
                      label={translatedTextsObject.houseBol}
                      disabled={disabled}
                      inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 12 }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('houseBol', e.target.value.trim().toUpperCase(), {
                            shouldValidate: true
                          })
                        }
                      }}
                      size='small'
                    />
                  </CngGridItem>
                )}
                {exportMot !== "40" && (
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerNumber}>
                    <CngTextField
                      required={exportMot !== '40' && msgFunction === '3' || msgFunction === '7'}
                      name='containerNumber'
                      label={translatedTextsObject.containerNumber}
                      disabled={disabled}
                      inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 14 }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('containerNumber', e.target.value.trim().toUpperCase(), {
                            shouldValidate: true
                          })
                        }
                      }}
                      size='small'
                    />
                  </CngGridItem>
                )}
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.firmLocation}>
                  <FirmCodesAutocompleteField
                    required={(exportMot !== '40' && exportMot !== '41') && (msgFunction === '1' || msgFunction === '2' || msgFunction === '3')}
                    name='firmLocation'
                    label={translatedTextsObject.firmLocation}
                    disabled={disabled}
                    key={getValues("firmLocation")}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.arrivalDate}>
                  <CngDateField
                    required
                    name='arrivalDate'
                    label={translatedTextsObject.arrivalDate}
                    format='YYYY-MM-DD'
                    disabled={disabled}
                    // ampm={false}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.arrivalTime}>
                  <CngTimeField
                    required
                    name='arrivalTime'
                    label={translatedTextsObject.arrivalTime}
                    format='HH:mm'
                    disabled={disabled}
                    ampm={false}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.inbondDestination}>
                  <NaUSPortAutocompleteField
                    required={msgFunction === '1' || msgFunction === '2' || msgFunction === '3' || msgFunction === 'Z'}
                    name='inbondDestination'
                    label={translatedTextsObject.inbondDestination}
                    disabled={disabled}
                    key={getValues("inbondDestination")}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.inbondCarrierCode}>
                  <CngTextField
                    required={msgFunction === 'A'}
                    name='inbondCarrierCode'
                    label={translatedTextsObject.inbondCarrierCode}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 4 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('inbondCarrierCode', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                    size='small'
                  />
                </CngGridItem>
                
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.bondedCarrierId}>
                  <CngTextField
                    required={msgFunction === 'A' || msgFunction === 'Z'}
                    name='bondedCarrierId'
                    label={translatedTextsObject.bondedCarrierId}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 12 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('bondedCarrierId', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                    size='small'
                  />
                </CngGridItem>
                
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.city}>
                  <CngTextField
                  required={msgFunction === 'A'}
                    name='city'
                    label={translatedTextsObject.city}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 19 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('city', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                    size='small'
                  />
                </CngGridItem>
                
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.stateCode}>
                  <NaAceStateCodeAutoCompleteField
                    required={city}
                    name='stateCode'
                    label={translatedTextsObject.stateCode}
                    disabled={disabled}
                    // countryCode={getValues(`licensePlateCountry1`)}
                    key={getValues("stateCode")}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.exportMot}>
                  <CngCodeMasterAutocompleteField
                    required={getValues("exportConveyance") && (msgFunction === '5' || msgFunction === '6' || msgFunction === '7')}
                    name='exportMot'
                    label={translatedTextsObject.exportMot}
                    disabled={disabled}
                    codeType='ACE_HW_ABI_EXPORT_MOT'
                    onChange={(e) => {
                      setValue("containerNumber", "");
                      setValue("houseBol", "");
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.exportConveyance}>
                  <CngTextField
                    required={exportMot && (msgFunction === '5' || msgFunction === '6' || msgFunction === '7')}
                    name='exportConveyance'
                    label={translatedTextsObject.exportConveyance}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 23 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('exportConveyance', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                    size='small'
                  />
                </CngGridItem>
          </Grid>
        </CngSection>
      </Grid>
      <CngGridItem xs={12} shouldHide={shouldHideMap.msgFunction}>
        <CngSection title={translatedTextsObject.msgFunction}>
          <CngCodeMasterAutocompleteField
            required
            size='small'
            name='msgFunction'
            label={translatedTextsObject.msgFunction}
            disabled={disabled}
            codeType='ACE_HW_ABI_ACTION'
            key={getValues('msgFunction')}
          />
        </CngSection>
      </CngGridItem>
    </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  console.log("toClient"+JSON.stringify(serverData))
  let localData = serverData;
  // localData.filerCode = localData.filerCode + localData.portCode
  localData.masterBolIssuerCode = localData.masterBolIssuerCode + localData.masterBol

  if(localData.createdDate){
    localData.createdDate = moment(localData.createdDate).tz("Canada/Eastern").format();
  }
  
  if(localData.updatedDate){
    localData.updatedDate = moment(localData.updatedDate).tz("Canada/Eastern").format();
  }

  if(localData.submittedDate){
    localData.submittedDate = moment(localData.submittedDate).tz("Canada/Eastern").format();
  }
  
  if(localData.responseDate){
    localData.responseDate = moment(localData.responseDate).tz("Canada/Eastern").format();
  }

  localData.arrivalTime = moment(localData.inbondDate).tz("Canada/Eastern").format('HH:mm');
  localData.arrivalDate = moment(localData.inbondDate).tz("Canada/Eastern").format('YYYY-MM-DD');
  console.log("afterToClient"+JSON.stringify(serverData))
  return localData;
}

function toServerDataFormat(localData) {
  console.log("toServer"+JSON.stringify(localData))
  
  // var filerCodePortCode = localData.filerCode
  // localData.filerCode = filerCodePortCode.substring(0,3)
  // if(filerCodePortCode.length>3){
  //   localData.portCode = filerCodePortCode.substring(3,filerCodePortCode.length)
  // }
  
  var masterBolWithIssuerCode = localData.masterBolIssuerCode
  if("40" === localData.exportMot){
    if(masterBolWithIssuerCode && masterBolWithIssuerCode.length > 3){
      localData.masterBol = masterBolWithIssuerCode.substring(3,masterBolWithIssuerCode.length)
    }else{
      localData.masterBol = ""
    }
    
    localData.masterBolIssuerCode = masterBolWithIssuerCode.substring(0,3)
  }else{
    if(masterBolWithIssuerCode && masterBolWithIssuerCode.length > 4){
      localData.masterBol = masterBolWithIssuerCode.substring(4,masterBolWithIssuerCode.length)
    }else{
      localData.masterBol = ""
    }
    
    localData.masterBolIssuerCode = masterBolWithIssuerCode.substring(0,4)
  }

  localData.inbondDateString = localData.arrivalDate +" "+ localData.arrivalTime;

  console.log("afterToServer"+JSON.stringify(localData))
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
