import React, { useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import {
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core'
import { components, DateTimeFormatter, useServices, useTranslation, constants } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import makeValidationSchema from './MakeValidationSchema'
import NaUSPortAutocompleteField from 'src/components/na/autocomplete/codemaintenance/NaUSPortAutocompleteField'
import NaHwyAcePOLPortAutocompleteField from 'src/components/na/autocomplete/codemaintenance/NaAceHwyPOLPortAutocompleteField'
import {FirmCodesAutocompleteField} from 'src/components/na'
import { NaAceStateCodeAutoCompleteField } from 'src/components/na'
import CngSection from '../../../components/cngcomponents/CngSection'
import moment from 'moment-timezone'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import { useLocation } from 'react-router-dom'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import Switch from 'src/components/Switch'
import ConfirmDialog from '../../common/ConfirmDialog'
import BillOfLadingDialog from './BillOfLadingDialog'
import BillOfLadingTable from './BillOfLadingTable'
import CryptoJS from 'crypto-js';

const {
  button: { CngButton, CngIconButton },
  form: {
    field: {
      CngCheckboxField,
      CngSelectField,
      CngTextField,
      CngDateField,
      CngTimeField,
      CngDateTimeField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField
    }
  },
  table: { useFetchCodeMaintenanceLookup },
  CngGridItem
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  submittedBy: "",
  submittedDate: "",
  version: "",
  partyId: "",
  filerCode: "SY2",
  portCode: "0901",
  actionCode: "",
  responseDate: "",
  bondNumber: "",
  entryType: "",
  inbondCarrierCode: "",
  destinationPort: "",
  foreignPort: "",
  inbondValue: "",
  inbondCarrierIdType: "IRS",
  inbondCarrierId: "",
  ftzIndicator: false,
  btaFdaIndicator: false,
  conveyanceCarrierCode: "",
  mot: "",
  carrierCountry: "",
  conveyanceName: "",
  tripNumber: "",
  arrivalPort: "",
  eta: "",
  ftzFirmsCode: "",
  bol: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { securedSendRequest, deleteRecord } = useServices();
  const { setValue, getValues, watch, reset, trigger } = useFormContext();
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)
  const translatedTextsObject = makeTranslatedTextsObject()
  const location = useLocation();
  const { id } = useParams()

  const [bolDialog, setBolDialog] = useState({
    open: false,
    bol: null
  })
  const bol = watch('bol')
  const ftz = watch('ftzIndicator')
  const entryType = watch('entryType')
  const mot = watch('mot')
  const inbondCarrierId = watch('inbondCarrierId')
  const inbondCarrierIdType = watch('inbondCarrierIdType')
  const filerCode = watch('filerCode')
  const portCode = watch('portCode')
  const [filerDropDown, setFilerDropDown] = useState()
  const [filerPortCodeList, setFilerPortCodeList] = useState()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    data: null
  })
  const [defaultFilerCode, setDefaultFilerCode] = useState("SY2")
  const [defaultPortCode, setDefaultPortCode] = useState("0901")

  const [placeHolderValue, setPlaceHolderValue] = useState()
  useEffect(() => {
    if (inbondCarrierIdType === 'IRS') {
      setPlaceHolderValue(translatedTextsObject.inbondCarrierIdIRS)
    } else if (inbondCarrierIdType === 'SSN') {
      setPlaceHolderValue(translatedTextsObject.inbondCarrierIdSSN)
    } else if (inbondCarrierIdType === 'CBP') {
      setPlaceHolderValue(translatedTextsObject.inbondCarrierIdCBP)
    }else{
      setPlaceHolderValue('')
    }
  }, [inbondCarrierIdType])

  useEffect(() => {
    if(ftz){
      setValue("carrierCountry", "")
      setValue("conveyanceName", "")
      // setValue("eta", "")
    }
  }, [ftz]);

  useEffect(() => {
    if(entryType === "62"){
      setValue("btaFdaIndicator", true)
    }else{
      setValue("btaFdaIndicator", false)
    }
  }, [entryType]);

  const [user, setUser] = useState([]); //change to filer code and port code from profile
  useEffect(() => { 
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest,fileForUserDetails); 
  }, []);

  useEffect(() => {
    Promise.all([
      // Default Filer Code & Port Code
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ABI_INBOND_DEFAULT_VALUE' }],
        undefined, 'code'
      )
    ]).then(([defaultABIValue]) => {
      if (null !== defaultABIValue) {
        if (undefined !== defaultABIValue.ABI_INBOND_DEFAULT_FILER_CODE) {
          setDefaultFilerCode(defaultABIValue.ABI_INBOND_DEFAULT_FILER_CODE)
        }
        if (undefined !== defaultABIValue.ABI_INBOND_DEFAULT_PORT_CODE) {
          setDefaultPortCode(defaultABIValue.ABI_INBOND_DEFAULT_PORT_CODE)
        }
      }
    }
    )
  }, [])

  useEffect(() => {
    if (user) {
      let filerCodeDropDown = [];
      let filerPortList = [];

      if (user.filerPort && user.filerPort.length === 0) {
        let filerDropDownObj = {
          text: defaultFilerCode,
          value: defaultFilerCode
        }
        let filerPortObj = {
          text: defaultFilerCode,
          value: defaultPortCode
        }
        filerCodeDropDown.push(filerDropDownObj)
        filerPortList.push(filerPortObj)

        if (filerCode === defaultFilerCode && portCode !== defaultPortCode) {
          setValue("portCode", defaultPortCode)
        }
      }

      if (user.filerPort && user.filerPort.length > 0) {
        filerCodeDropDown = [];
        filerPortList = [];
        user.filerPort.forEach((value) => {
          let filerDropDownObj = {
            text: value.filerCode,
            value: value.filerCode
          }
          let filerPortObj = {
            text: value.filerCode,
            value: value.portCode
          }

          filerCodeDropDown.push(filerDropDownObj)
          filerPortList.push(filerPortObj)

          if (filerCode === value.filerCode && portCode !== value.portCode) {
            setValue("portCode", value.portCode)
          }
        })
      }

      if (filerCodeDropDown.length > 0 && !filerCodeDropDown.includes(filerCode)) {
        setValue("filerCode", filerCodeDropDown[0].value)
      }

      setFilerDropDown(filerCodeDropDown)
      setFilerPortCodeList(filerPortList)
    }
  }, [user, defaultFilerCode, defaultPortCode]);
  
  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let abiInbond7512 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.TITLE
    )
    let id = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ID
    )
    let createdBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.UPDATED_DATE
    )
    let submittedBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.SUBMITTED_DATE
    )
    let version = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.VERSION
    )
    let partyId = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.PARTY_ID
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.STATUS
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.RESPONSE_DATE
    )
    let inbondHeader = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_HEADER
    )
    let filerCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.FILER_CODE
    )
    let portCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.PORT_CODE
    )
    let actionCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ACTION_CODE
    )
    let bondNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.BOND_NUMBER
    )
    let entryType = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ENRTY_TYPE
    )
    let inbondCarrierCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_CARRIER_CODE
    )
    let destinationPort = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.DESTINATION_PORT
    )
    let foreignPort = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.FOREIGN_PORT
    )
    let inbondValue = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_VALUE
    )
    let inbondCarrierIdType = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_CARRIER_ID_TYPE
    )
    let inbondCarrierId = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_CARRIER_ID
    )
    let inbondCarrierIdNote = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_CARRIER_ID_NOTE
    )
    let inbondCarrierIdIRS = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_CARRIER_ID_IRS
    )
    let inbondCarrierIdSSN = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_CARRIER_ID_SSN
    )
    let inbondCarrierIdCBP = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_CARRIER_ID_CBP
    )
    let ftzIndicator = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.FTZ_INDICATOR
    )
    let btaFdaIndicator = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.BTA_FDA_INDICATOR
    )
    let conveyanceInformation = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CONVEYANCE_INFORMATION
    )
    let conveyanceCarrierCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CONVEYANCE_CARRIER_CODE
    )
    let mot = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.MOT
    )
    let carrierCountry = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CARRIER_COUNTRY
    )
    let conveyanceName = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CONVEYANCE_NAME
    )
    let tripNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.TRIP_NUMBER
    )
    let arrivalPort = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ARRIVAL_PORT
    )
    let eta = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ETA
    )
    let ftzFirmsCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.FTZ_FIRMS_CODE
    )
    let bolSummary = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.BOL_SUMMARY
    )
    
    return {
      abiInbond7512,
      id,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      submittedBy,
      submittedDate,
      version,
      partyId,
      status,
      responseDate,
      inbondHeader,
      filerCode,
      portCode,
      actionCode,
      bondNumber,
      entryType,
      inbondCarrierCode,
      destinationPort,
      foreignPort,
      inbondValue,
      inbondCarrierIdType,
      inbondCarrierId,
      inbondCarrierIdNote,
      inbondCarrierIdIRS,
      inbondCarrierIdSSN,
      inbondCarrierIdCBP,
      ftzIndicator,
      btaFdaIndicator,
      conveyanceInformation,
      conveyanceCarrierCode,
      mot,
      carrierCountry,
      conveyanceName,
      tripNumber,
      arrivalPort,
      eta,
      ftzFirmsCode,
      bolSummary
    }
  }

  function handleRemoveBol(data) {
    const bol = [...getValues('bol')].filter((b) =>
      b.id ? b.id !== data.id : b._id !== data._id
    )

    setValue('bol', bol, { shouldValidate: true })
  }

  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection
          title={translatedTextsObject.inbondHeader}
          subheader={
            <Typography color='textSecondary' variant='caption'>
              <Typography variant='inherit' color='error'>
                *
              </Typography>
              Mandatory fields
            </Typography>
          }
        >
          <Grid container spacing={1}>
            <CngGridItem xs={12}>
              <Grid container spacing={1}>
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.filerCode}>
                  <CngSelectField
                    required
                    name="filerCode"
                    label={translatedTextsObject.filerCode}
                    disabled={disabled}
                      items={filerDropDown}
                      onChange={(e) => {
                        setValue('portCode', filerPortCodeList.find(obj => obj.text === e.target.value).value)
                      }}
                  />
                  </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.entryType}>
                  <CngCodeMasterAutocompleteField
                    required
                    name='entryType'
                    label={translatedTextsObject.entryType}
                    disabled={disabled}
                    codeType='ACE_HW_ABI_ENTRY_TYPE'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.bondNumber}>
                  <CngTextField
                    required
                    name='bondNumber'
                    label={translatedTextsObject.bondNumber}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 12 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('bondNumber', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.inbondCarrierCode}>
                  <CngTextField
                    required
                    name='inbondCarrierCode'
                    label={translatedTextsObject.inbondCarrierCode}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 4 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('inbondCarrierCode', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.destinationPort}>
                  <NaUSPortAutocompleteField
                    required
                    name='destinationPort'
                    label={translatedTextsObject.destinationPort}
                    disabled={disabled}
                    key={getValues("destinationPort")}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.foreignPort}>
                  <NaHwyAcePOLPortAutocompleteField
                    required={entryType==="63"}
                    name='foreignPort'
                    label={translatedTextsObject.foreignPort}
                    disabled={disabled || entryType==="61"}
                    key={getValues("foreignPort")}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.inbondValue}>
                  <CngTextField
                    required
                    name='inbondValue'
                    label={translatedTextsObject.inbondValue}
                    disabled={disabled}
                    inputProps={{ maxLength: 8 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('inbondValue', e.target.value.trim(), {
                          shouldValidate: true
                        })
                      }
                    }}
                  />
                </CngGridItem>
                  <CngGridItem xs={12} sm={6} shouldHide={true}>
                    <CngCodeMasterAutocompleteField
                      required
                      name='actionCode'
                      label={translatedTextsObject.actionCode}
                      disabled={disabled}
                      codeType='ACE_HW_ABI_ACTION'
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} md={3} shouldHide={shouldHideMap.ftzIndicator}>
                    <CngCheckboxField
                      label={translatedTextsObject.ftzIndicator}
                      name='ftzIndicator'
                      onChange={(e) =>
                        setValue('ftzIndicator', e.target.checked, {
                          shouldValidate: true
                        })
                      }
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} md={3} shouldHide={shouldHideMap.btaFdaIndicator}>
                    <CngCheckboxField
                      label={translatedTextsObject.btaFdaIndicator}
                      name='btaFdaIndicator'
                      onChange={(e) =>
                        setValue('btaFdaIndicator', e.target.checked, {
                          shouldValidate: true
                        })
                      }
                      disabled={entryType === "61" || entryType === "63"}
                    />
                  </CngGridItem>

                <CngGridItem xs={12} sm={2} md={2} shouldHide={shouldHideMap.inbondCarrierIdType}>
                  <CngCodeMasterAutocompleteField
                    required={getValues("actionCode") === 'A'}
                    name='inbondCarrierIdType'
                    label={translatedTextsObject.inbondCarrierIdType}
                    disabled={disabled}
                    codeType='ACE_HW_ABI_INBOND_CARRIER_TYPE'
                    onChange={(e) => {
                      let word = getValues("inbondCarrierId").replaceAll("-","")
                      let word2 = word.substring(4, word.length)
                      console.log(word2)
                      if (e === 'IRS' && word.length > 2) {
                        word = word.substring(0, 2) + '-' + word.substring(2, 11)
                      } else if (e === 'SSN' &&  word.length > 3) {
                        word = word.substring(0, 3) + '-' + word.substring(3, 10)
                        console.log("1 "+word)
                        if (word2.length > 1 && !word2.includes('-')) {
                          word = word.substring(0, 6) + '-' + word.substring(6, 10)
                          console.log("2 "+word)
                        }
                      } else if (e === 'CBP' && word.length > 6) {
                        word = word.substring(0, 6) + '-' + word.substring(6, 11)
                      }

                      setValue('inbondCarrierId', word, {
                        shouldValidate: true
                      })
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={10} sm={4} shouldHide={shouldHideMap.inbondCarrierId}>
                  <CngTextField
                    required={getValues("actionCode") === 'A'}
                    name='inbondCarrierId'
                    label={translatedTextsObject.inbondCarrierId}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: inbondCarrierIdType === 'SSN' ? 11 : 12 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        let word = e.target.value.toUpperCase().trim();
                        // Remove all '-' characters
                        word = word.replace(/-/g, '');
                        let word2 = word.substring(4, word.length)
                        console.log(word2)
                        if (inbondCarrierIdType === 'IRS' && word.length > 2) {
                          word = word.substring(0, 2) + '-' + word.substring(2, 11)
                        } else if (inbondCarrierIdType === 'SSN' &&  word.length > 3) {
                          word = word.substring(0, 3) + '-' + word.substring(3, 10)
                          if (word2.length > 1 && !word2.includes('-')) {
                            word = word.substring(0, 6) + '-' + word.substring(6, 10)
                          }
                        } else if (inbondCarrierIdType === 'CBP' && word.length > 6) {
                          word = word.substring(0, 6) + '-' + word.substring(6, 11)
                        }

                        setValue('inbondCarrierId', word, {
                          shouldValidate: true
                        })
                      }
                      
                    }}
                    placeholder={placeHolderValue}
                    helperText={translatedTextsObject.inbondCarrierIdNote}
                  />
                  </CngGridItem>

              </Grid>
            </CngGridItem>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={translatedTextsObject.conveyanceInformation}
          subheader={
            <Typography color='textSecondary' variant='caption'>
              <Typography variant='inherit' color='error'>
                *
              </Typography>
              Mandatory fields
            </Typography>
          }
        >
          <Grid container spacing={1}>
            <CngGridItem xs={12}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.mot}>
                  <CngCodeMasterAutocompleteField
                    required
                    name='mot'
                    label={translatedTextsObject.mot}
                    disabled={disabled}
                    codeType='ACE_HW_ABI_MOT'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tripNumber}>
                  <CngTextField
                    name='tripNumber'
                    label={translatedTextsObject.tripNumber}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 5 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('tripNumber', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.conveyanceCarrierCode}>
                  <CngTextField
                    required
                    name='conveyanceCarrierCode'
                    label={translatedTextsObject.conveyanceCarrierCode}
                    disabled={disabled}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 4 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('conveyanceCarrierCode', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.arrivalPort}>
                  <NaUSPortAutocompleteField
                    required
                    name='arrivalPort'
                    label={translatedTextsObject.arrivalPort}
                    disabled={disabled}
                    key={getValues("arrivalPort")}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierCountry}>
                  <CngCountryAutocompleteField
                    name="carrierCountry"
                    label={translatedTextsObject.carrierCountry}
                    disabled={disabled || ftz || mot==="40"}
                    lookupProps={{
                      label: (record) => {
                        return `${record.code};${record.descriptionEn}`
                      }
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eta}>
                  <CngDateField
                    required
                    name='eta'
                    label={translatedTextsObject.eta}
                    format='YYYY-MM-DD'
                    disabled={disabled}
                    // ampm={false}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.conveyanceName}>
                  <CngTextField
                    name='conveyanceName'
                    label={translatedTextsObject.conveyanceName}
                    disabled={disabled || ftz || mot==="40"}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 23 }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue('conveyanceName', e.target.value.trim().toUpperCase(), {
                          shouldValidate: true
                        })
                      }
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ftzFirmsCode}>
                  <FirmCodesAutocompleteField
                    required={ftz}
                    name='ftzFirmsCode'
                    label={translatedTextsObject.ftzFirmsCode}
                    disabled={disabled || !ftz}
                    key={getValues("ftzFirmsCode")}
                  />
                </CngGridItem>
              </Grid>
            </CngGridItem>
           
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
      <CngSection
          title={
            <Typography style={{ fontWeight: 700 }}>
              {translatedTextsObject.bolSummary}{' '}
              <Typography color='textSecondary' component='span' variant='caption'>
                {`/ ${bol.length} Entries`}
              </Typography>
            </Typography>
          }
          subheader={
            <Typography color='textSecondary' variant='caption'>
              <Typography variant='inherit' color='error'>
                Note: Please add at least one Bill of Lading.
              </Typography>
            </Typography>
          }
        >
          <BillOfLadingTable
            data={bol}
            onAddBol={() =>
              setBolDialog({ open: true, bol: null })
            }
            onEditBol={(bol) =>
              setBolDialog({ open: true, bol })
            }
            onDeleteBol={handleRemoveBol}
          />
          <BillOfLadingDialog
            onClose={() =>
              setBolDialog({ open: false, bol: null })
            }
            open={bolDialog.open}
            bol={bolDialog.bol}
            ftz={ftz}
            mot={mot}
            showNotification={showNotification}
          />
          <ConfirmDialog
            isConfirmDialogOpen={confirmDialog.open}
            closeDialog={() => setConfirmDialog({ open: false, data: null })}
            confirmDialog={handleRemoveBol}
            content="Items that you delete can't be restored. Are you sure about this?"
            okMsg='Yes, delete'
            cancelMsg='No, take me back'
            title= 'Delete'
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.actionCode}>
          <Grid container>
              <CngGridItem xs={12} shouldHide={shouldHideMap.actionCode}>
                <CngCodeMasterAutocompleteField
                  required
                  size='small'
                  name='actionCode'
                  label={translatedTextsObject.actionCode}
                  disabled={disabled}
                  codeType='ACE_HW_ABI_7512_ACTION'
                />
              </CngGridItem>
          </Grid>
        </CngSection>
      </Grid>
    </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;

  localData.createdDate = moment(localData.createdDate).tz("Canada/Eastern").format();
  localData.updatedDate = moment(localData.updatedDate).tz("Canada/Eastern").format();
  localData.submittedDate = moment(localData.submittedDate).tz("Canada/Eastern").format();
  localData.responseDate = moment(localData.responseDate).tz("Canada/Eastern").format();
  
  localData.bol.forEach((datum) => {
    if((datum.manifestQuantity && datum.manifestQuantity > 0) || (datum.weight && datum.weight > 0) || 
     (datum.volume && datum.volume > 0) || (datum.placeOfPrereceipt && datum.placeOfPrereceipt.length > 0) || datum.bolContainer.length > 0){
      datum.additionalBOLIndicator = true
    }

    if(datum.bolContainer.length > 0){
      datum.containerizedIndicator = true
    }
  });

  console.log("toClient"+JSON.stringify(serverData))
  return localData;
}

function toServerDataFormat(localData) {

  // var portCode = filerPortCodeList.find(obj => obj.text === localData.filerCode).value;
  // localData.portCode = portCode
  localData.arrivalDate = moment(localData.eta).format('YYYY-MM-DD');

  localData.bol.forEach((datum) => {
    if(!datum.additionalBOLIndicator){
      datum.foreignPortOfLading = null;
      datum.manifestQuantity = null;
      datum.manifestUnit = null;
      datum.weight = null;
      datum.weightUnit = null;
      datum.volume = null;
      datum.volumeUnit = null;
      datum.placeOfPrereceipt = null;
      datum.bolContainer = null;
      datum.parties = null;
    }
  });

  localData.submittedDate = DateTimeFormatter.toServerDateTime(
    localData.submittedDate
  );

  localData.responseDate = DateTimeFormatter.toServerDateTime(
    localData.responseDate
  );

  console.log("toServer"+JSON.stringify(localData))
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
